import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { useMemo } from 'react';
import { Box } from 'components/Box';
import { FlexContainer } from 'components/FlexContainer';

import { isContentfulSVGImage } from 'helpers/isContentfulSVGImage';

import type { FC } from 'react';
import type { Partner } from 'types/partners/partner';

const StyledImage = styled.img`
  width: 100%;
`;

export const PartnerLogo: FC<{ partner: Partner }> = ({ partner }) => {
  const image = useMemo(
    () =>
      isContentfulSVGImage(partner.cardImage) ? (
        <StyledImage
          src={partner.cardImage.file.url}
          alt={partner.cardImage.title || partner.title}
        />
      ) : (
        <GatsbyImage
          image={partner.cardImage.gatsbyImageData!}
          alt={partner.cardImage.title || partner.title}
          objectFit="contain"
        />
      ),
    [partner.cardImage, partner.title]
  );

  return (
    <Box
      background="white"
      padding={2}
      paddingLeft={5}
      paddingRight={5}
      maxWidth="80px"
      borderRadius={7}
    >
      <FlexContainer justifyContent="center" alignItems="center">
        {image}
      </FlexContainer>
    </Box>
  );
};
