import styled from 'styled-components';

export const PartnerImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  background-color: ${props => props.theme.colors.white};

  img {
    max-height: 120px;
    max-width: 200px !important; // this is done to style the image, overriding the baked in gatsby image styles
  }
`;
