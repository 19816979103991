import { RightArrowIcon } from 'components/Button';
import { LinkAppearance, PartnerCardLink } from 'components/Links';
import { AddTradeContainer } from './AddTradeContainer.styled';
import { PartnerCardContainer } from './PartnerCardContainer.styled';

import type { FC } from 'react';

export const AddTradeTile: FC = () => (
  <PartnerCardContainer>
    <PartnerCardLink
      to="/partners/add-buy-sell-trades-manually/"
      title="Add buy & sell trades manually | Sharesight"
    >
      <AddTradeContainer>
        <p>
          Can't find your broker?
          <br />
          <LinkAppearance>Add a trade manually</LinkAppearance>
        </p>
        <RightArrowIcon weight="bold" />
      </AddTradeContainer>
    </PartnerCardLink>
  </PartnerCardContainer>
);
