import { GatsbyImage } from 'gatsby-plugin-image';
import { isContentfulSVGImage } from 'helpers/isContentfulSVGImage';
import { PartnerCardLink } from 'components/Links';
import { PartnerCardContainer } from './PartnerCardContainer.styled';
import { PartnerImageContainer } from './PartnerImageContainer.styled';

import type { FC } from 'react';
import type { Partner } from 'types/partners/partner';

export const PartnerCard: FC<{ entry: Partner }> = ({ entry }) => {
  const image = isContentfulSVGImage(entry.cardImage) ? (
    <img src={entry.cardImage.file.url} alt={entry.cardImage.title || entry.title} />
  ) : (
    <GatsbyImage
      objectFit="contain"
      image={entry.cardImage.gatsbyImageData!}
      alt={entry.cardImage.title || entry.title}
    />
  );

  return (
    <PartnerCardContainer>
      <PartnerCardLink to={entry.urlSlug} title={`${entry.title} | Sharesight`}>
        <PartnerImageContainer>{image}</PartnerImageContainer>
      </PartnerCardLink>
    </PartnerCardContainer>
  );
};
