import { useMemo } from 'react';

import { ContentCardsContainer } from 'components/Container';
import { PartnerCard } from './PartnerCard';
import { AddTradeTile } from './AddTradeTile';

import type { FC } from 'react';
import type { Partner } from 'types/partners/partner';

export const PartnerCards: FC<{
  entries: Partner[];
  includeAddTradeTile?: boolean;
}> = ({ entries, includeAddTradeTile = false }) => {
  const partnerCards = useMemo(
    () => entries.map(entry => <PartnerCard key={entry.id} entry={entry} />),
    [entries]
  );

  return (
    <ContentCardsContainer columns={3} gap={1}>
      {partnerCards}
      {includeAddTradeTile && <AddTradeTile />}
    </ContentCardsContainer>
  );
};
