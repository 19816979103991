import { Container } from 'components/Container';
import { StyledLink } from './StyledLink.styled';

import type { Partner } from 'types/partners/partner';
import type { FC } from 'react';

export const PartnerSideMenu: FC<{ partner: Partner }> = ({ partner }) => (
  <Container padding={6} bottomPadding={1} background="callout">
    <nav>
      {partner.website && (
        <p>
          Website <br />
          <StyledLink to={partner.website}>{partner.title}</StyledLink>
        </p>
      )}
      <p>
        Location <br />
        <strong>{partner.location ? partner.location : 'Worldwide'}</strong>
      </p>
      {partner.phoneNumber && (
        <p>
          Contact <br />
          <strong>{partner.phoneNumber}</strong>
        </p>
      )}
      {partner.partnerType && (
        <p>
          Partner type <br />
          <strong>{partner.partnerType}</strong>
        </p>
      )}
      {partner.categories && (
        <p>
          Partner Categories <br />
          {partner.categories.map(p => (
            <strong key={p.urlSlug}>{p.name}</strong>
          ))}
        </p>
      )}
    </nav>
  </Container>
);
