import { lighten } from 'polished';
import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const AddTradeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  height: 100%;
  padding: 0 ${getDimension('size6')};

  background-color: ${props => lighten(0.55, props.theme.colors.primary)};

  p {
    margin-bottom: 0;
  }
`;
